import { authToken } from './authentication';
import { reverseSortBy } from './utils';

const apiHost = process.env.BACKEND_HOST;
const progress = {};
const retryDelay = 1500;

const apiFetch = (key, path, options) => {
  if (progress[key]) {
    return Promise.resolve(null);
  }
  progress[key] = true;

  options = options || {};
  options.method = options.method || 'GET';
  options.headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Access-Token': authToken(),
  };

  return window
    .fetch(`${apiHost}${path}`, options)
    .then((raw) => {
      progress[key] = false;
      return raw.json();
    })
    .catch((error) => {
      console.error(`${key}:`, error);
      setTimeout(() => {
        progress[key] = false;
      }, retryDelay);
    });
};

//
// Admin Users
//
export const getAllAdminUsers = (page, perPage) => {
  return apiFetch(
    'getAllAdminUsers',
    `/admin/admin_users?page=${page}&perPage=${perPage}`
  ).then((response) => {
    if (response) {
      const adminUsers = response.data.adminUsers.sort(
        reverseSortBy('createdAt')
      );
      const total = response.data.total;
      return { adminUsers, total };
    }
  });
};

export const getAdminUser = (uuid) => {
  return apiFetch('getAdminUser', `/admin/admin_users/${uuid}`).then(
    (response) => {
      if (response) {
        return response.data.adminUser;
      }
    }
  );
};

export const createAdminUser = (email, password) => {
  return apiFetch('createAdminUser', '/admin/admin_users', {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((response) => response.data);
};

export const updateAdminUser = (uuid, email, password) => {
  return apiFetch('updateAdminUser', `/admin/admin_users/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((response) => response.data);
};

export const deleteAdminUser = (uuid) => {
  return apiFetch('deleteAdminUser', `/admin/admin_users/${uuid}`, {
    method: 'DELETE',
  }).then((response) => response.data);
};

//
// Customers
//
export const getAllCustomers = (page, perPage) => {
  return apiFetch(
    'getAllCustomers',
    `/admin/customers?page=${page}&perPage=${perPage}`
  ).then((response) => {
    if (response) {
      const customers = response.data.customers.sort(
        reverseSortBy('createdAt')
      );
      const total = response.data.total;
      return { customers, total };
    }
  });
};

export const getCustomer = (uuid) => {
  return apiFetch('getCustomer', `/admin/customers/${uuid}`).then(
    (response) => {
      if (response) {
        const customer = response.data.customer;
        if (typeof customer.data === 'string') {
          customer.data = JSON.parse(customer.data);
        }
        return customer;
      }
    }
  );
};

export const createCustomer = (params) => {
  return apiFetch('createCustomer', `/admin/customers`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then((response) => response.data);
};

export const updateCustomer = (uuid, params) => {
  return apiFetch('updateCustomer', `/admin/customers/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  }).then((response) => response.data);
};

export const deleteCustomer = (uuid) => {
  return apiFetch('deleteCustomer', `/admin/customers/${uuid}`, {
    method: 'DELETE',
  }).then((response) => response.data);
};

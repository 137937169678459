import * as moment from 'moment';

export const sortBy = (field) => (a, b) => {
  if (a[field] < b[field]) {
    return -1;
  } else if (a[field] > b[field]) {
    return 1;
  } else {
    return 0;
  }
};

export const reverseSortBy = (field) => (a, b) => {
  if (a[field] < b[field]) {
    return 1;
  } else if (a[field] > b[field]) {
    return -1;
  } else {
    return 0;
  }
};

export const formatDatetime = (t) => moment(t).format('DD MMM YYYY, hh:mm');

export const isEmail = (value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
